import React, { useEffect, useRef } from "react"
import { loadGoogleMapsScript } from "../../../utils/loadingGoogleMaps"

interface GoogleAutocompleteProps {
  placeholder?: string
  onPlaceSelected: (lat: number, lon: number) => void
  className?: string
}

const GoogleAutocomplete: React.FC<GoogleAutocompleteProps> = ({
  placeholder,
  onPlaceSelected,
  className,
}) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const apiKey = import.meta.env.VITE_GOOGLE_MAPS_API_KEY // Access API key from .env

  useEffect(() => {
    loadGoogleMapsScript(apiKey)
      .then(() => {
        if (inputRef.current) {
          const autocomplete = new google.maps.places.Autocomplete(
            inputRef.current,
            {
              types: ["geocode"],
            },
          )

          autocomplete.addListener("place_changed", () => {
            const place = autocomplete.getPlace()
            if (place.geometry) {
              const lat = place.geometry.location?.lat()
              const lon = place.geometry.location?.lng()
              if (lat && lon) {
                onPlaceSelected(lat, lon)
              }
            }
          })
        }
      })
      .catch((error) => {
        console.error("Error loading Google Maps script:", error)
      })
  }, [apiKey, onPlaceSelected])

  return (
    <input
      ref={inputRef}
      type="text"
      placeholder={placeholder ?? "Search places..."}
      className={className ?? "border py-2 px-3 w-full rounded"}
    />
  )
}

export default GoogleAutocomplete
