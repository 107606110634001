import DashboardLayout from "./DashboardLayout"
import { Route, Routes } from "react-router-dom"
import { VendorComponents } from "../../features/vendor"
import ProtectedRoutes from "../../services/auth/ProtectedRoutes"
import { useGetCurrentVendorQuery } from "../../services/auth/api"
import { Suspense, useEffect } from "react"
import { useAppDispatch } from "../../app/hooks"
import { setVendor } from "../../features/vendor/vendorSlice"
import Spinner from "../reusables/Spinner"

function VendorDashboard() {
  const { data: currentVendor } = useGetCurrentVendorQuery()
  const dispatch = useAppDispatch()
  useEffect(() => {
    currentVendor && dispatch(setVendor(currentVendor))
  }, [currentVendor, dispatch])
  return (
    <DashboardLayout>
      <Routes>
        <Route
          path=""
          element={
            <ProtectedRoutes
              element={
                <Suspense
                  fallback={
                    <>
                      <Spinner className="text-theme_secondary" />
                    </>
                  }
                >
                  <VendorComponents.LazyDashboard />
                </Suspense>
              }
              roles={["ROLE_VENDOR"]}
            />
          }
        />
        <Route
          path="listings"
          element={
            <ProtectedRoutes
              element={
                <Suspense
                  fallback={
                    <>
                      <Spinner className="text-theme_secondary" />
                    </>
                  }
                >
                  <VendorComponents.Listings.LazyMyListings />
                </Suspense>
              }
              roles={["ROLE_VENDOR"]}
            />
          }
        />
        <Route
          path="add-new-listing"
          element={
            <ProtectedRoutes
              element={
                <Suspense
                  fallback={
                    <>
                      <Spinner className="text-theme_secondary" />
                    </>
                  }
                >
                  <VendorComponents.Listings.LazyAddNewListing />
                </Suspense>
              }
              roles={["ROLE_VENDOR"]}
            />
          }
        />
        <Route
          path="profile"
          element={
            <ProtectedRoutes
              element={
                <Suspense
                  fallback={
                    <>
                      <Spinner className="text-theme_secondary" />
                    </>
                  }
                >
                  <VendorComponents.LazyProfile />
                </Suspense>
              }
              roles={["ROLE_VENDOR"]}
            />
          }
        />
        <Route
          path="reviews"
          element={
            <ProtectedRoutes
              element={
                <Suspense
                  fallback={
                    <>
                      <Spinner className="text-theme_secondary" />
                    </>
                  }
                >
                  <VendorComponents.LazyReviews />
                </Suspense>
              }
              roles={["ROLE_VENDOR"]}
            />
          }
        />
      </Routes>
    </DashboardLayout>
  )
}

export default VendorDashboard
