import { Combobox, Transition } from "@headlessui/react"
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/24/outline"
import React, { Fragment, useState } from "react"
import { useFormContext, Controller } from "react-hook-form"
import Spinner from "./Spinner"

interface SelectProps {
  name: string
  className?: string
  classNameInput?: string
  options: string[]
  defaultValue?: string
  label?: string
  rules?: Record<string, any>
  onChange?: any
  placeholder?: string
  disabled?: boolean
  reset?: boolean
}

const CustomSelect = React.forwardRef<HTMLSelectElement, SelectProps>(
  (
    {
      name,
      className,
      classNameInput,
      options,
      defaultValue,
      disabled,
      label,
      rules,
      onChange,
      placeholder,
      reset,
    },
    ref,
  ) => {
    const [query, setQuery] = useState("")
    const formContext = useFormContext()
    if (!formContext) {
      return <Spinner className="text-theme_secondary" />
    }
    const { control, setValue } = formContext
    const { errors } = formContext.formState

    const filteredOptions =
      query === ""
        ? options
        : options.filter((option) =>
            option
              .toLowerCase()
              .replace(/\s+/g, "")
              .includes(query.toLowerCase().replace(/\s+/g, "")),
          )

    const handleClear = () => {
      setValue(name, "")
      onChange("")
    }

    return (
      <div className="w-full relative">
        {label && (
          <label htmlFor={name} className="block text-gray-700 font-bold mb-1">
            {label || name}
          </label>
        )}
        <Controller
          name={name}
          control={control}
          rules={rules}
          render={({ field }) => (
            <div className="w-full">
              <Combobox
                value={field.value || null}
                onChange={(e) => {
                  field.onChange(e)
                  onChange(e)
                }}
              >
                <div className={`relative`}>
                  <div
                    className={`${className} relative w-full border cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 text-xs`}
                  >
                    <Combobox.Input
                      className={`${classNameInput} w-full border-none py-2 text-xs text-gray-900 focus:ring-0`}
                      // displayValue={(placeholder) => placeholder}
                      onChange={(event) => {
                        setQuery(event.target.value)
                        onChange(() => event.target.value)
                      }}
                      value={
                        field.value || field.value === "" ? null : field.value
                      }
                      placeholder={defaultValue ?? placeholder}
                    />
                    <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronUpDownIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </Combobox.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    afterLeave={() => setQuery("")}
                  >
                    <Combobox.Options
                      className={`${
                        disabled && "hidden"
                      } absolute z-10 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-xs shadow-lg ring-1 ring-black/5 focus:outline-none`}
                    >
                      {filteredOptions.length === 0 && query !== "" ? (
                        <div className="relative cursor-default select-none px-1 py-2 text-gray-700">
                          Nothing found.
                        </div>
                      ) : (
                        filteredOptions.map((option, i) => (
                          <Combobox.Option
                            key={i}
                            className={({ active }) =>
                              `relative cursor-default select-none py-2 pl-4 pr-4 ${
                                active
                                  ? "bg-theme_secondary text-white"
                                  : "text-gray-900"
                              }`
                            }
                            value={option}
                          >
                            {({ selected, active }) => (
                              <>
                                <span
                                  className={`block truncate ${
                                    selected ? "font-medium" : "font-normal"
                                  }`}
                                >
                                  {option}
                                </span>
                                {selected ? (
                                  <span
                                    className={`absolute inset-y-0 left-0 flex items-center ${
                                      active
                                        ? "text-white"
                                        : "text-thembg-theme_secondary"
                                    }`}
                                  >
                                    <CheckIcon
                                      className="h-3 w-3"
                                      aria-hidden="true"
                                    />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Combobox.Option>
                        ))
                      )}
                    </Combobox.Options>
                  </Transition>
                </div>
              </Combobox>
              {field.value && (
                <button
                  type="button"
                  className="absolute text-xs text-gray-500 top-1/2 right-8 transform -translate-y-1/2 cursor-pointer"
                  onClick={handleClear}
                >
                  X
                </button>
              )}
              {errors[name] && errors[name]?.message && (
                <p className="text-red-500 text-left text-xs mt-1">
                  {errors[name]?.message as any}
                </p>
              )}
            </div>
          )}
        />
      </div>
    )
  },
)

export default CustomSelect
