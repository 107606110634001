import { lazy } from "react"
// import { auth } from "./auth"
import { Listings } from "./listings"

const LazyDashboard = lazy(() => import("./dashboard/Dashboard"))
const LazyProfile = lazy(() => import("./profile/Profile"))
const LazyReviews = lazy(() => import("./reviews/Reviews"))
const LazySocialMedia = lazy(() => import("./social_media/SocialMedia"))
export const VendorComponents = {
  // auth,
  LazyDashboard,
  Listings,
  LazyProfile,
  LazyReviews,
  LazySocialMedia,
}
