import React from "react"
import { useNavigate } from "react-router-dom"
import CustomIcon from "./icons/CustomIcon"
import { IconDefinition } from "@fortawesome/fontawesome-svg-core"
import RatingComponent from "./RatingComponent"
import { TopEventsLisitingsCardProps } from "../../utils/types"
import ImageCustom from "./ImageCustom"

function TopEventsListingsCard(props: TopEventsLisitingsCardProps) {
  const navigate = useNavigate()
  return (
    <div
      onClick={() =>
        navigate(`/listing-singular/${props.listingId}`, {
          state: { listingId: props.listingId },
        })
      }
      className="cursor-pointer"
    >
      <div className="rounded">
        <div className="group relative">
          <div className="w-full flex flex-row font-theme_secondary_light justify-between items-center px-2 absolute transition-all ease-in duration-200 group-hover:space-x-0 lg:space-x-4 mt-2">
            <div className="bg-white space-x-2 py-1 px-1 hidden transition-all ease-in duration-200 group-hover:hidden lg:flex items-center rounded-full">
              <CustomIcon
                type={"faStar" as unknown as IconDefinition}
                className="text-white bg-theme_primary rounded-full p-1 text-[0.5rem]"
              />
              <span className="text-[0.6rem]">Featured</span>
            </div>
            {/* <div className="bg-white space-x-2 py-0.5 px-1 text-[0.6rem] flex items-center rounded-full">
              <CustomIcon
                type={"faTag" as unknown as IconDefinition}
                className="text-white bg-theme_primary transition-all ease-in duration-200 group-hover:bg-theme_secondary rounded-[100%] p-1"
              />
              <span className="whitespace-nowrap">
                UGX(
                {props.price !== undefined &&
                  (props.price < 1000
                    ? `${props.price}`
                    : props.price < 1000000
                    ? `${props.price / 1000}K`
                    : `${props.price / 1000000}M`)}
                )
              </span>
            </div> */}
          </div>
          <ImageCustom
            src={props.imageUrl}
            blurredImg="/images/placeholder-light.png"
            className="w-full rounded-t h-52 ease-in-out duration-75"
          />
          <div className="flex flex-row justify-between items-center px-2 -mt-8">
            <span className="font-theme_secondary_bold uppercase text-white transition-all ease-in duration-200 bg-theme_primary group-hover:bg-theme_secondary px-3 py-[0.2rem] text-[0.5rem] rounded-full"></span>
            <CustomIcon
              type={"faHeart" as unknown as IconDefinition}
              className="text-white bg-theme_primary transition-all ease-in duration-200 group-hover:bg-theme_secondary p-2 rounded-full text-[0.6rem]"
            />
          </div>
        </div>
        <div className="bg-gray-200 rounded-b py-6 divide-y divide-gray-300">
          <div className="pl-2 pb-4">
            <div className="flex flex-row justify-between items-center pr-2">
              <p className="font-theme_secondary_bold text-sm">
                {props.vendorName}
              </p>
              <CustomIcon
                type={"faCheck" as unknown as IconDefinition}
                className="bg-theme_secondary text-white text-[0.6rem] rounded-full p-[0.1rem]"
              />
            </div>
            <div className="flex flex-row items-center space-x-1 pt-2">
              <CustomIcon
                type={"faLocationDot" as unknown as IconDefinition}
                className="text-theme_secondary text-[0.6rem] rounded-full p-[0.1rem]"
              />
              <p className="font-theme_secondary_light text-gray-700 text-[0.65rem]">
                {props.vendorLocation}
              </p>
            </div>
          </div>
          <div className="flex flex-row items-center pl-2 pt-2">
            <div>
              <RatingComponent defaultValue={props.ratings} />
            </div>
            <span className="font-theme_secondary_light text-[0.65rem] text-gray-700">
              ({props.reviews} reviews)
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TopEventsListingsCard
