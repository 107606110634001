import { useEffect } from "react"
import { Link, useNavigate } from "react-router-dom"
import { useAppDispatch } from "../../../app/hooks"
import {
  setDashboard,
  setIsOpen,
} from "../../../layouts/sidebars/sideBarsSlice"
import { categoriesMenu } from "../../../utils/data"
import CustomIcon from "../../../layouts/reusables/icons/CustomIcon"
import { IconDefinition } from "@fortawesome/fontawesome-svg-core"
import { useLazySearchListingsQuery } from "../../../services/auth/api"
import { setSearchResults } from "../vendor_listings/searchSlice"
import { toast } from "react-toastify"

function CategoriesMenu() {
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(setDashboard(false))
  })
  const [trigger] = useLazySearchListingsQuery()
  const navigate = useNavigate()
  const handleCategoryClick = (
    route: any,
    dataType: "vendors" | "listings",
    state: any = null,
  ) => {
    navigate(route, { state: { dataType, ...state } })
  }
  const handleClick = async (categoryName: string) => {
    await trigger({ categoryName: categoryName })
      .unwrap()
      .then((response: any[]) => {
        dispatch(setSearchResults({ listings: response }))
        handleCategoryClick("/listing-with-map", "listings", {
          fromSearch: true,
        })
      })
      .catch((error: any) => {
        toast.warn(error.data.error)
      })
  }
  return (
    <div className="flex flex-col gap-y-2 text-theme_black font-theme_secondary_bold m-4">
      {categoriesMenu.map((category, i) => (
        <div key={i}>
          <div
            className={`flex flex-row items-center justify-start gap-x-3 cursor-pointer`}
            onClick={() => {
              dispatch(setIsOpen(false))
              handleClick(category.title)
            }}
          >
            <CustomIcon
              type={category.icon as unknown as IconDefinition}
              className={`${
                category.title === "Cakes" && "pl-0.5"
              }  text-theme_secondary text-lg`}
            />
            <div
              className={`${
                category.title === "Cakes" && "pl-0.5"
              } text-sm hover:text-theme_secondary`}
            >
              {category.title}
            </div>
          </div>
        </div>
      ))}
      <div>
        <Link
          to={"/vendors"}
          className="flex flex-row text-sm hover:text-theme_secondary space-x-3"
          onClick={() => dispatch(setIsOpen(false))}
        >
          <div>
            <CustomIcon type={"faEllipsis" as unknown as IconDefinition} />
          </div>
          <div>Other Categories</div>
        </Link>
      </div>
    </div>
  )
}

export default CategoriesMenu
