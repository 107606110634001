let googleMapsScriptLoaded = false

export const loadGoogleMapsScript = (apiKey: string) => {
  return new Promise<void>((resolve, reject) => {
    if (googleMapsScriptLoaded) {
      resolve()
      return
    }

    const script = document.createElement("script")
    script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`
    script.async = true
    script.onload = () => {
      googleMapsScriptLoaded = true
      resolve()
    }
    script.onerror = () =>
      reject(new Error("Failed to load Google Maps script"))
    document.head.appendChild(script)
  })
}
