import Header from "../header/Header"
import Footer from "../footer/Footer"
import BottomFooter from "../footer/BottomFooter"
import SideBarDashboard from "../sidebars/dashboards/SideBarDashboard"

function DashboardLayout({ children }: { children: any }) {
  return (
    <div className={"dashboard"}>
      <Header />
      <div className="container grid grid-cols-1 lg:grid-cols-[auto,1fr] mx-auto lg:mt-[2%]">
        <div className="hidden lg:block">
          <SideBarDashboard />
        </div>
        <div>{children}</div>
      </div>
      <Footer />
      <BottomFooter />
    </div>
  )
}

export default DashboardLayout
