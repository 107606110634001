import { VendorListings } from "./vendor_listings"
import { lazy } from "react"

const LazyHome = lazy(() => import("../../features/general/home/Home"))
const LazyContact = lazy(() => import("../../features/general/Contact"))
const LazyVendors = lazy(() => import("../../features/general/Vendors"))
const LazyLogin = lazy(() => import("../../features/general/auth/Login"))
const LazyCustomerSignUp = lazy(
  () => import("../../features/general/auth/CustomerSignUp"),
)
const LazyVendorSignUp = lazy(
  () => import("../../features/general/auth/VendorSignUp"),
)
const LazyAdminLogin = lazy(
  () => import("../../features/admin/auth/AdminLogin"),
)
const LazyTermsAndConditions = lazy(
  () => import("../../features/general/TermsAndConditions"),
)
const LazyForgotPassword = lazy(
  () => import("../../features/general/auth/ForgotPassword"),
)
const LazyResetPassword = lazy(
  () => import("../../features/general/auth/ResetPassword"),
)
const LazyAboutUs = lazy(() => import("../../features/general/about/AboutUs"))
const LazyDataProtectAndGuidelines = lazy(
  () => import("../../features/general/DataProtectionAndGuidelines"),
)

export const GeneralComponents = {
  LazyContact,
  LazyHome,
  LazyVendors,
  VendorListings,
  LazyLogin,
  LazyCustomerSignUp,
  LazyVendorSignUp,
  LazyAdminLogin,
  LazyForgotPassword,
  LazyTermsAndConditions,
  LazyResetPassword,
  LazyAboutUs,
  LazyDataProtectAndGuidelines,
}
