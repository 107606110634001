import { lazy } from "react"

const LazyDashboard = lazy(() => import("./dashboard/Dashboard"))
const LazyVendors = lazy(() => import("./vendors/Vendors"))
const LazyListings = lazy(() => import("./listings/Listings"))
const LazyCustomers = lazy(() => import("./customers/Customers"))
const LazyPayments = lazy(() => import("./payments/Payments"))
const LazyCategories = lazy(() => import("./categories/Categories"))
const LazyProfile = lazy(() => import("./profile/Profile"))
export const AdminComponents = {
  LazyDashboard,
  LazyVendors,
  LazyListings,
  LazyCustomers,
  LazyPayments,
  LazyCategories,
  LazyProfile,
}
