// Import the utility function
import { useState, useRef, useEffect } from "react"
import {
  GoogleMap,
  Marker,
  InfoWindow,
  Autocomplete,
} from "@react-google-maps/api"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { setPosition } from "./mapSlice"
import Spinner from "../Spinner"
import RatingComponent from "../RatingComponent"
import { useNavigate } from "react-router-dom"
import { loadGoogleMapsScript } from "../../../utils/loadingGoogleMaps"

type Library = "places" | "geometry" | "drawing" | "visualization"
const libraries = ["places"] as Library[]

function LocationMarker({ position }: { position: google.maps.LatLngLiteral }) {
  const dispatch = useAppDispatch()
  const markerRef = useRef<google.maps.Marker | null>(null)

  const onDragEnd = () => {
    if (markerRef.current) {
      const newPosition = markerRef.current.getPosition()
      if (newPosition) {
        const lat = newPosition.lat()
        const lng = newPosition.lng()
        dispatch(setPosition({ lat, lng }))
      }
    }
  }

  return (
    <Marker
      position={position}
      draggable
      onDragEnd={onDragEnd}
      onLoad={(marker) => (markerRef.current = marker)}
    />
  )
}

const Map = ({
  height,
  className,
  position,
  settable = false,
  popUpComponent,
  popUpComponentData,
  searchBusinessLocationLabel,
  dataType,
  vendors,
  listings,
}: {
  height: number
  className?: string
  position?: google.maps.LatLngLiteral
  settable?: boolean
  popUpComponent?: boolean
  popUpComponentData?: any
  searchBusinessLocationLabel?: boolean
  dataType?: "vendors" | "listings"
  vendors?: any[]
  listings?: any[]
}) => {
  const [isScriptLoaded, setScriptLoaded] = useState(false)
  const mapPositionFromCache = useAppSelector((state) => state.map)
  const [zoom, setZoom] = useState(
    dataType === "vendors" || dataType === "listings" ? 10 : 13,
  )
  const [selectedItem, setSelectedItem] = useState<any | null>(null)
  const [autocomplete, setAutocomplete] =
    useState<google.maps.places.Autocomplete | null>(null)
  const [searchInput, setSearchInput] = useState("")
  const [mapCenter, setMapCenter] = useState<google.maps.LatLngLiteral>(
    position || {
      lat: mapPositionFromCache.LatLng.lat,
      lng: mapPositionFromCache.LatLng.lng,
    },
  )
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const mapRef = useRef<google.maps.Map | null>(null)
  const apiKey = import.meta.env.VITE_GOOGLE_MAPS_API_KEY

  // Load Google Maps script once
  useEffect(() => {
    loadGoogleMapsScript(apiKey)
      .then(() => setScriptLoaded(true))
      .catch((error) =>
        console.error("Error loading Google Maps script:", error),
      )
  }, [apiKey])

  useEffect(() => {
    setZoom(dataType === "vendors" || dataType === "listings" ? 10 : 13)
  }, [dataType])

  if (!isScriptLoaded) {
    return <Spinner className="text-theme_secondary" />
  }

  const handleAutocompleteLoad = (autoC: google.maps.places.Autocomplete) => {
    setAutocomplete(autoC)
  }

  const handlePlaceChanged = () => {
    if (autocomplete) {
      const place = autocomplete.getPlace()
      if (place.geometry && place.geometry.location) {
        const lat = place.geometry.location.lat()
        const lng = place.geometry.location.lng()
        setMapCenter({ lat, lng })
        dispatch(setPosition({ lat, lng }))
      }
    }
  }

  const handleMarkerClick = (item: any) => {
    setSelectedItem(item)
  }

  const handleMapClick = (event: google.maps.MapMouseEvent) => {
    if (event.latLng) {
      const lat = event.latLng.lat()
      const lng = event.latLng.lng()
      setMapCenter({ lat, lng })
      dispatch(setPosition({ lat, lng }))
    }
  }

  const data = dataType === "vendors" ? vendors : listings

  return (
    <div className={className}>
      <div style={{ marginBottom: "10px" }}>
        <Autocomplete
          onLoad={handleAutocompleteLoad}
          onPlaceChanged={handlePlaceChanged}
        >
          <>
            {searchBusinessLocationLabel && (
              <label htmlFor="#mapSearchForLocation" className="text-sm">
                Search for your specific business location here.
              </label>
            )}
            <input
              type="text"
              placeholder="Search for a location"
              id="mapSearchForLocation"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              style={{
                width: "100%",
                height: "40px",
                padding: "10px",
                borderRadius: "5px",
                border: "1px solid #ccc",
                boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
              }}
            />
          </>
        </Autocomplete>
      </div>

      <GoogleMap
        center={mapCenter}
        zoom={zoom}
        mapContainerStyle={{ height: `${height}px`, width: "100%" }}
        onLoad={(map) => {
          mapRef.current = map
        }}
        options={{
          draggableCursor: "pointer",
          draggingCursor: "grabbing",
        }}
        onClick={handleMapClick}
      >
        {data &&
          data.map((item, i) => (
            <Marker
              key={i}
              position={{
                lat: item.gps.latitude || 0.347596,
                lng: item.gps.longitude || 32.58252,
              }}
              onClick={() => handleMarkerClick(item)}
            />
          ))}
        {selectedItem && (
          <InfoWindow
            position={{
              lat: selectedItem.gps.latitude || 0.347596,
              lng: selectedItem.gps.longitude || 32.58252,
            }}
            onCloseClick={() => setSelectedItem(null)}
          >
            <div className="info-window">
              <img
                src={selectedItem.featuredImage || ""}
                alt="item"
                className="object-cover object-center"
              />
              <h2>{selectedItem.businessName || selectedItem.listingName}</h2>
              <button
                onClick={() =>
                  navigate(`/listing-singular/${selectedItem.listingId}`, {
                    state: { listingId: selectedItem.listingId },
                  })
                }
                className="capitalize text-white bg-theme_secondary hover:bg-theme_primary rounded text-center px-4 py-1"
              >
                Request Pricing
              </button>
            </div>
          </InfoWindow>
        )}
        {settable && <LocationMarker position={mapCenter} />}
      </GoogleMap>
    </div>
  )
}

export default Map
