import { ToastContainer, toast } from "react-toastify"
import { Route, Routes } from "react-router-dom"
import NoMatch from "./features/error-components/NoMatch"
import "react-toastify/dist/ReactToastify.min.css"
import CustomerDashboard from "./layouts/dashboards/CustomerDashboard"
import VendorDashboard from "./layouts/dashboards/VendorDashboard"
import MainLayout from "./layouts/main/MainLayout"
import AdminDashboard from "./layouts/dashboards/AdminDashboard"
import { ScrollToTop } from "./utils/ScrollToTop"
import { useEffect, useState } from "react"
import { slides } from "./features/general/home/carousel/slides"
import Spinner from "./layouts/reusables/Spinner"
import { topEventsVendors } from "./utils/data"

export default function App() {
  const [isLoading, setLoading] = useState(false)
  const images = [
    ...slides,
    ...topEventsVendors,
    {
      source:
        "https://res.cloudinary.com/dczjol1a4/image/upload/f_auto,q_auto/v1/images/home/n4jlt6oyis8cgxqnurtp.jpg",
    },
  ]
  useEffect(() => {
    const preloadImages = () => {
      const promises = images.map((slide: any) => {
        return new Promise((resolve, reject) => {
          const img = new Image()
          const imageSrc = slide.imageUrl ? slide.imageUrl : slide.source
          img.src = imageSrc
          img.onload = resolve
          img.onerror = reject
        })
      })

      Promise.all(promises)
        .then(() => {
          setLoading(false)
        })
        .catch((error) => {
          toast.error("Error preloading images:", error)
          setLoading(true)
        })
    }

    preloadImages()
  }, [])

  return (
    <>
      {isLoading ? (
        <div className="absolute top-0 bottom-0 left-0 right-0 w-full h-full bg-theme_primary/80">
          <Spinner className="text-theme_secondary" />
        </div>
      ) : (
        <>
          <ToastContainer position="top-center" />
          <ScrollToTop>
            <Routes>
              <Route path="*" element={<MainLayout />} />
              <Route path="/customer/*" element={<CustomerDashboard />} />
              <Route path="/vendor/*" element={<VendorDashboard />} />
              <Route path="/admin/*" element={<AdminDashboard />} />
              <Route path="*" element={<NoMatch />} />
            </Routes>
          </ScrollToTop>
        </>
      )}
    </>
  )
}
