// src/services/places/api.ts
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

export const placesApi = createApi({
  reducerPath: "placesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://maps.googleapis.com/maps/api/place",
  }),
  endpoints: (builder) => ({
    searchPlaces: builder.query<Array<any>, string>({
      query: (query) =>
        `textsearch/json?query=${encodeURIComponent(query)}&key=${
          import.meta.env.VITE_GOOGLE_MAPS_API_KEY
        }`,
    }),
  }),
})

export const { useSearchPlacesQuery } = placesApi
