import DashboardLayout from "./DashboardLayout"
import { Route, Routes } from "react-router-dom"
import ProtectedRoutes from "../../services/auth/ProtectedRoutes"
import { CustomerComponents } from "../../features/customer"
import { useGetCurrentCustomerQuery } from "../../services/auth/api"
import { useAppDispatch } from "../../app/hooks"
import { Suspense, useEffect } from "react"
import { setCustomer } from "../../features/customer/customerSlice"
import Spinner from "../reusables/Spinner"

function CustomerDashboard() {
  const { data: currentCustomer } = useGetCurrentCustomerQuery()
  const dispatch = useAppDispatch()
  useEffect(() => {
    currentCustomer && dispatch(setCustomer(currentCustomer))
  }, [currentCustomer, dispatch])
  return (
    <DashboardLayout>
      <Routes>
        <Route
          path=""
          element={
            <ProtectedRoutes
              element={
                <Suspense
                  fallback={
                    <>
                      <Spinner className="text-theme_secondary" />
                    </>
                  }
                >
                  <CustomerComponents.LazyDashboard />
                </Suspense>
              }
              roles={["ROLE_CUSTOMER"]}
            />
          }
        />
        <Route
          path="profile"
          element={
            <ProtectedRoutes
              element={
                <Suspense
                  fallback={
                    <>
                      <Spinner className="text-theme_secondary" />
                    </>
                  }
                >
                  <CustomerComponents.LazyProfile />
                </Suspense>
              }
              roles={["ROLE_CUSTOMER"]}
            />
          }
        />
        <Route
          path="vendor-manager"
          element={
            <ProtectedRoutes
              element={
                <Suspense
                  fallback={
                    <>
                      <Spinner className="text-theme_secondary" />
                    </>
                  }
                >
                  <CustomerComponents.LazyVendorManager />
                </Suspense>
              }
              roles={["ROLE_CUSTOMER"]}
            />
          }
        />
      </Routes>
    </DashboardLayout>
  )
}

export default CustomerDashboard
