import { createContext, useContext, ReactNode } from "react"
import { useNavigate } from "react-router-dom"
import { User, logout, setUser } from "./authSlice"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { toast } from "react-toastify"

interface AuthContextProps {
  user: User
  login: (userData: User) => void
  logoutFunction: () => void
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined)

export const AuthProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const user = useAppSelector((state) => state.auth.user)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const login = (userData: User) => {
    dispatch(setUser(userData))

    if (userData.includes("ROLE_ADMIN")) {
      navigate("/admin/vendors")
    } else if (userData.includes("ROLE_VENDOR")) {
      navigate("/vendor")
    } else if (userData.includes("ROLE_CUSTOMER")) {
      navigate("/customer")
    }
  }

  const logoutFunction = () => {
    dispatch(logout())
    navigate("/")
    toast.success("Logged out successfully")
  }

  return (
    <AuthContext.Provider value={{ user, login, logoutFunction }}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = (): AuthContextProps => {
  const context = useContext(AuthContext)

  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider")
  }

  return context
}
