import { PayloadAction, createSlice } from "@reduxjs/toolkit"

interface VendorState {
  id: number
  fullName: string
  email: string
  isEmailVerified: boolean
  phoneNumber: string
  isPhoneVerified: boolean
  nationalId: string
  nationalIdStatus: boolean
  nationalIdComments: string | null
  certificateOfIncorporation: string
  certificateOfIncorporationStatus: boolean
  certificateOfIncorporationComments: string | null
  latitude: number
  longitude: number
  district: string | null
  profileImage: string
  categoryName: string
  brandImage: string
  isActive: boolean
  daysUntilExpiration: number
}

const initialState: VendorState = {
  id: 0,
  fullName: "",
  email: "",
  isEmailVerified: false,
  phoneNumber: "",
  isPhoneVerified: false,
  nationalId: "",
  nationalIdStatus: false,
  nationalIdComments: null,
  certificateOfIncorporation: "",
  certificateOfIncorporationStatus: false,
  certificateOfIncorporationComments: null,
  latitude: 0.347596,
  longitude: 32.58252,
  district: "",
  profileImage: "",
  categoryName: "",
  brandImage: "",
  isActive: true,
  daysUntilExpiration: 1,
}
const vendorSlice = createSlice({
  name: "vendorSlice",
  initialState,
  reducers: {
    setVendor: (state, action: PayloadAction<VendorState>) => {
      state.id = action.payload.id
      state.fullName = action.payload.fullName
      state.email = action.payload.email
      state.isEmailVerified = action.payload.isEmailVerified
      state.phoneNumber = action.payload.phoneNumber
      state.isPhoneVerified = action.payload.isPhoneVerified
      state.nationalId = action.payload.nationalId
      state.nationalIdStatus = action.payload.nationalIdStatus
      state.nationalIdComments = action.payload.nationalIdComments
      state.certificateOfIncorporation =
        action.payload.certificateOfIncorporation
      state.certificateOfIncorporationStatus =
        action.payload.certificateOfIncorporationStatus
      state.certificateOfIncorporationComments =
        action.payload.certificateOfIncorporationComments
      state.latitude = action.payload.latitude
      state.longitude = action.payload.longitude
      state.district = action.payload.district
      state.profileImage = action.payload.profileImage
      state.categoryName = action.payload.categoryName
      state.brandImage = action.payload.brandImage
      state.isActive = action.payload.isActive
      state.daysUntilExpiration = action.payload.daysUntilExpiration
    },
  },
})

export const { setVendor } = vendorSlice.actions
export default vendorSlice.reducer
