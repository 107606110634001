import { lazy, Suspense, useState } from "react"
import { Route, Routes, useLocation } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { showFooter } from "./mainLayoutSlice"
import { useEffect } from "react"
import { GeneralRoutes } from "../routes/general/routes"
import Header from "../header/Header"
import { GeneralComponents } from "../../features/general"
import Footer from "../footer/Footer"
import BottomFooter from "../footer/BottomFooter"
import Spinner from "../reusables/Spinner"

// Import specific components for lazy loading
const LazyHome = lazy(() => import("../../features/general/home/Home"))
const LazyListingWithMap = lazy(
  () => import("../../features/general/vendor_listings/ListingWithMap"),
)
const LazyListingSingular = lazy(
  () => import("../../features/general/vendor_listings/ListingSingular"),
)
const LazyVendors = lazy(() => import("../../features/general/Vendors"))
const LazyContact = lazy(() => import("../../features/general/Contact"))
const LazyLogin = lazy(() => import("../../features/general/auth/Login"))
const LazyCustomerSignUp = lazy(
  () => import("../../features/general/auth/CustomerSignUp"),
)
const LazyVendorSignUp = lazy(
  () => import("../../features/general/auth/VendorSignUp"),
)
const LazyAdminLogin = lazy(
  () => import("../../features/admin/auth/AdminLogin"),
)
const LazyTermsAndConditions = lazy(
  () => import("../../features/general/TermsAndConditions"),
)
const LazyForgotPassword = lazy(
  () => import("../../features/general/auth/ForgotPassword"),
)
const LazyResetPassword = lazy(
  () => import("../../features/general/auth/ResetPassword"),
)
const LazyAboutUs = lazy(() => import("../../features/general/about/AboutUs"))
const LazyDataProtectAndGuidelines = lazy(
  () => import("../../features/general/DataProtectionAndGuidelines"),
)

function MainLayout() {
  const [isComponentLoaded, setComponentLoaded] = useState(false)
  const showFooterVariable = useAppSelector(
    (state) => state.mainLayout.showFooter,
  )
  const dispatch = useAppDispatch()
  const location = useLocation()

  useEffect(() => {
    const timer = setTimeout(() => setComponentLoaded(true), 175)
    return () => clearTimeout(timer)
  }, [])

  useEffect(() => {
    location.pathname === "/listing-with-map"
      ? dispatch(showFooter(false))
      : dispatch(showFooter(true))
  }, [location.pathname, dispatch])

  return (
    <div>
      {isComponentLoaded && <Header />}
      <div>
        <Routes>
          {GeneralRoutes.map((route, key) => {
            const LazyComponent =
              route.component ===
              GeneralComponents.VendorListings.ListingWithMap
                ? LazyListingWithMap
                : route.component ===
                  GeneralComponents.VendorListings.LazyListingSingular
                ? LazyListingSingular
                : route.component === GeneralComponents.LazyVendors
                ? LazyVendors
                : route.component === GeneralComponents.LazyContact
                ? LazyContact
                : route.component === GeneralComponents.LazyLogin
                ? LazyLogin
                : route.component === GeneralComponents.LazyCustomerSignUp
                ? LazyCustomerSignUp
                : route.component === GeneralComponents.LazyVendorSignUp
                ? LazyVendorSignUp
                : route.component === GeneralComponents.LazyAdminLogin
                ? LazyAdminLogin
                : route.component === GeneralComponents.LazyTermsAndConditions
                ? LazyTermsAndConditions
                : route.component === GeneralComponents.LazyForgotPassword
                ? LazyForgotPassword
                : route.component === GeneralComponents.LazyResetPassword
                ? LazyResetPassword
                : route.component === GeneralComponents.LazyAboutUs
                ? LazyAboutUs
                : route.component ===
                  GeneralComponents.LazyDataProtectAndGuidelines
                ? LazyDataProtectAndGuidelines
                : LazyHome

            return (
              <Route
                path={route.path}
                element={
                  <Suspense
                    fallback={
                      <>
                        <Spinner className="text-theme_secondary" />
                      </>
                    }
                  >
                    <LazyComponent
                      dataType={route.dataType as "listings" | "vendors"}
                    />
                  </Suspense>
                }
                key={key}
              />
            )
          })}
        </Routes>
      </div>
      {isComponentLoaded && showFooterVariable && <Footer />}
      {isComponentLoaded && <BottomFooter />}
    </div>
  )
}

export default MainLayout
