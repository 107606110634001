import { lazy } from "react"
// import { auth } from "./auth"

const LazyDashboard = lazy(() => import("./dashboard/Dashboard"))
const LazyCustomer = lazy(() => import("./Customer"))
const LazyProfile = lazy(() => import("./profile/Profile"))
const LazyVendorManager = lazy(() => import("./vendor_manager/VendorManager"))

export const CustomerComponents = {
  LazyCustomer,
  // auth,
  LazyDashboard,
  LazyProfile,
  LazyVendorManager,
}
