import React, { ComponentProps, useEffect, useState } from "react"
import CustomIcon from "./icons/CustomIcon"
import { IconDefinition } from "@fortawesome/fontawesome-svg-core"

type RatingComponentProps = ComponentProps<"div"> & {
  onRatingChange?: (rating: number) => void
  defaultValue?: number
}

function RatingComponent(props: RatingComponentProps) {
  const [rating, setRating] = useState(0)

  useEffect(() => {
    if (
      props.defaultValue &&
      props.defaultValue >= 1 &&
      props.defaultValue <= 5
    ) {
      setRating(props.defaultValue)
      props.onRatingChange && props.onRatingChange(props.defaultValue)
    }
  }, [props, props.defaultValue, props.onRatingChange])

  const maxRating = 5
  const starArray = Array.from(
    { length: Math.min(maxRating, rating) },
    (_, i) => i + 1,
  )

  const handleStarClick = (index: number) => {
    setRating(index + 1)
    props.onRatingChange && props.onRatingChange(index + 1)
  }
  return (
    <div className={props.className}>
      {starArray.map((_, i) => (
        <CustomIcon
          type={"faStar" as unknown as IconDefinition}
          className="text-yellow-400 rounded-full p-1 text-[0.5rem] cursor-pointer"
          key={i}
          onClick={() => handleStarClick(i)}
        />
      ))}
      {Array.from({ length: maxRating - starArray.length }, (_, i) => (
        <CustomIcon
          key={i + starArray.length}
          type={"faStar" as unknown as IconDefinition}
          className="text-gray-600 rounded-full p-1 text-[0.5rem] cursor-pointer"
          onClick={() => handleStarClick(starArray.length + i)}
        />
      ))}
    </div>
  )
}

export default RatingComponent
